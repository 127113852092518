@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body {
    background: url('../public/MeTimeBot_Background.png') no-repeat center center fixed;
    background-size: cover;
    margin: 0;
    font-family: 'Roboto', 'Arial', sans-serif;
    color: #333;
    padding-top: 60px;
    line-height: 1.6;
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensures full viewport height */
}

.site-container {
    max-width: 1024px;
    width: 100%;
    margin: 0 auto; /* Center the site container */
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1; /* Allows the container to expand */
}

.navbar {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1em;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0);
    position: fixed; /* Fix the navbar to the top of the viewport */
    top: 0; /* Align it to the top */
    left: 0; /* Align it to the left */
    z-index: 1000; /* Ensure it stays on top of other content */
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #012d52;
    border: 2px solid #012d52;
}


.navbar a {
    color: #012d52;
    font-size: 1em;
    text-decoration: none;
    padding: 0.5em 1em;
    font-weight: bolder;
    transition: all 0.3s ease-in-out;
}

.navbar a:hover,
.navbar a.active {
    color: #ff6347;
    border-bottom: 2px solid #ff6347;
}

.header {
    text-align: center;
    margin: 0 auto;
}

/* Adjustments for email form */
#emailForm {
    position: relative; /* Changed from fixed to relative */
    width: 100%;
    max-width: 500px; /* Set a max width */
    margin: 0 auto; /* Center the form */
    padding: 1em;
}

/* Styles for input fields in email form */
#emailForm input[type=email], 
#emailForm input[type=submit] {
    width: 90%; /* Full width */
    margin-bottom: 1em;
    padding: 10px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(255,99,71,0.7);
}

#emailForm input[type=submit] {
    background-color: #ff6347;
    color: white;
    cursor: pointer;
    width: 40%; /* Full width */
}

#emailForm input[type=submit]:hover {
    background-color: #ff2d00;
}

.tabcontent {
    transition: all 0.3s ease-in-out;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1em;
}

.tabcontent .textblock {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #012d52;
    border: 2px solid #012d52;
    max-width: 600px;
    margin: 0 auto; /* Center the textblock */
}

h1, h2, h3 {
    color: #5a5a5a;
}

h1 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 0.5em;
}

h2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0.5em;
}

/* Styles for links and buttons in tab content */
.tabcontent .tabcontent .button {
    background-color: #f2b632;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.separator {
    width: 100%;
    height: 1px;
    background-color: #012d52;
    margin: 1em 0;
}

.tabcontent a:hover, .tabcontent .button:hover {
    background-color: #e0a422;
}

/*Text should flow on the right side of the image*/
.bulletImage {
    float: left;
    width: 10%;
    margin-right: 1em;
}

.profilePhoto {
    float: left;
    width: 20%;
    max-width: 300px;
    padding: 1em;
    border-radius: 50%;
    margin-bottom: 1em;
}

.faq-section {
    margin: 20px;
    font-family: Arial, sans-serif;
}

.accordion {
    background-color: #f2f2f2;
    color: #444;
    cursor: pointer;
    padding: 15px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    margin-bottom: 5px;
    font-size: 16px;
    border-radius: 5px;
}

.active, .accordion:hover {
    background-color: #ddd;
}

.panel {
    padding: 0 15px;
    background-color: white;
    display: none;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.panel.open {
    display: block;
    padding: 15px;
}

.contact-section {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
    background-color: #f3f3f3;
    border-radius: 8px;
}

.email-contact p, .social-contacts p {
    margin-bottom: 15px;
}

.social-contacts ul {
    list-style: none;
    padding: 0;
}

.social-contacts li {
    margin-bottom: 10px;
}

.social-contacts a, .email-contact a {
    color: #0077cc;
    text-decoration: none;
}

.social-contacts a:hover, .email-contact a:hover {
    text-decoration: underline;
}

.footer {
    background-color: #f3f3f3;
    padding: 20px;
    text-align: center;
    font-size: 14px;
    margin-top: auto; /* Ensures footer sticks to bottom of page */
}


/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .navbar a {
        font-size: 0.8em; /* Smaller font size for mobile */
    }

    h1 {
        font-size: 24px;
    }
}

@media screen and (max-width: 767px) {
    .navbar a {
        font-size: 0.8em; /* Adjusted font size for mobile devices */
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .navbar a {
        font-size: 0.9em; /* Adjusted font size for tablets and small desktops */
    }
}
